.react-datetimerange-picker__wrapper {
    line-height: 1.21428571em;
    padding: 7px;
    font-size: 1em;
    background: #fff;
    border: 1px solid rgba(34, 36, 38, .15);
    color: rgba(0, 0, 0, .87);
    border-radius: .28571429rem;
    box-shadow: 0 0 0 0 transparent inset;
}
